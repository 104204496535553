export function maxWidth(elements) {
    let mw = 0;
    elements.each(function () {
        const w = $(this).width();
        if (w > mw) {
            mw = w;
        }
    });
    return mw;
};
